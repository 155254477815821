.Event_wrap {
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
  grid-column: 1 / 13;
  display: -ms-grid;
  display: grid;
  height: 300px;
  -ms-flex-line-pack: center;
  align-content: center;
  margin-bottom: 20px;
}
.Event_date {
  background: -o-radial-gradient(
    top right,
    circle,
    #fea003,
    #ec5338 25%,
    #d0106d
  );
  background: radial-gradient(
    circle at top right,
    #fea003,
    #ec5338 25%,
    #d0106d
  );
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-column: 1 / 4;
}

.Event_info {
  border: 1px solid var(--br-grey);
  padding: 30px 80px;
}

.Event_bold {
  font-weight: 700;
}

.Event_info h1 {
  margin: 0 0 20px;
  color: var(--consumer-pink);
}

.Event_info p {
  margin-bottom: 0px;
}

.Event_info ul {
  padding: 0;
  list-style: none;
}

.Event_moreInfo {
  max-height: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.Event_moreInfo.Event_open {
  max-height: 300px;
}
